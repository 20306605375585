<div>
    <div mat-dialog-title>
        <div class="title-container">
            <div>
                <mat-icon>delete</mat-icon>
            </div>
            <p class="title">
                {{ "actions.delete_dialog.delete_info" | translate }}
            </p>
        </div>
    </div>
    <mat-dialog-content>
        <p>
            {{ "actions.delete_dialog.question" | translate }}
        </p>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <button
            type="button"
            (click)="closeDialog()"
            mat-stroked-button
            class="default-btn rounded"
            color="primary"
        >
            <mat-icon>close</mat-icon>
            <span> {{ "actions.delete_dialog.cancel" | translate }}</span>
        </button>
        <button
            type="button"
            color="warn"
            mat-flat-button
            class="default-btn rounded delete-btn white-txt"
            (click)="confirm()"
        >
            <mat-icon>delete</mat-icon>
            @if (submitText) {
            <span> {{ submitText }}</span>
            }@else {
            <span> {{ "actions.delete_dialog.confirm" | translate }}</span>
            }
        </button>
    </div>
</div>
