import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { BaseDialogData } from '../../../models/bases/base-dialog-data';

@Component({
    selector: 'app-delete-form-confirm-dialog',
    standalone: true,
    imports: [MatDialogModule, TranslateModule, MatButtonModule, MatIconModule],
    templateUrl: './delete-form-confirm-dialog.component.html',
    styleUrls: ['./delete-form-confirm-dialog.component.scss'],
})
export class DeleteFormConfirmDialogComponent implements OnInit {
    submitText: string | undefined;

    constructor(
        readonly _dialogRef: MatDialogRef<DeleteFormConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: BaseDialogData
    ) {}

    ngOnInit(): void {
        this.submitText = this.dialogData?.data.item?.submitText;
    }

    confirm(): void {
        this._dialogRef.close({ confirmed: true });
    }

    closeDialog(): void {
        this._dialogRef.close();
    }
}
